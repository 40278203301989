import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { signupStep1, validateEmailOTP } from "../api/Api";
import { ACCESS_TOKEN, ADMIN_TOKEN } from "../config/Config";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";
import { useLocation } from "react-router-dom";

const Signup = () => {
  const location = useLocation();
  const { planCost, planDuration, planName } = location.state || {};
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  const setUserName = useContextSelector(
    StateContext,
    (state) => state.setUserName
  );
  
  const navigate = useNavigate();
  const [otpPageEnabled, setOtpPageEnabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    orgName: "visualGlance",
    otp: "",
    channelName: "",
    planCost: "",
    planDuration: "",
    planName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const updateSubscription = () => {
    //alert("register"+JSON.stringify(props.jobApplicationProps));
    const req = formData;
    if (location.state) {
      req.planName = planName ? planName : '';
      req.planCost = planCost ? planCost : '';
      req.planDuration = planDuration ? planDuration : '';
    }
    setFormData(req);
    //alert("register"+JSON.stringify(this.state.registerRequest));
  };

  useEffect(() => {
    document.title = "Signup";
    updateSubscription();
    //onShowAlert();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.password = formData.mobile;
    console.log(formData);
    signupStep1(formData)
      .then((res) => {
        if (res.message === "Already signed up!! please click login.") {
          toast.success("Already signed up!! please click login.");
          navigate("/signin");
        } else {
          toast.success(
            `Hello dear ${formData.name}, Please check your email and enter the email otp to complete the sign up. ${formData.email}`
          );

          setOtpPageEnabled(true);
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
        }
        // setShowLoading(false);
        console.log(error);
      });
  };
  const handleOTPSubmit = (e) => {
    e.preventDefault();
    if (!formData.otp) {
      toast.error("Enter the OTP");
    } else {
      const otpValidationRequest = {
        email: formData.email,
        otp: formData.otp,
        mobileAsPassword: formData.password,
      };

      validateEmailOTP(otpValidationRequest)
        .then((res) => {
          localStorage.setItem(
            ACCESS_TOKEN,
            res.accessToken ? res.accessToken : null
          );
          setIsLoggedIn(true);
          let token = res.accessToken;
          const base64Url = token.split(".")[1];
          const base64 = base64Url.replace("-", "+").replace("_", "/");
          token = JSON.parse(window.atob(base64));
          if (token.exp <= Math.floor(Date.now() / 1000)) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ADMIN_TOKEN);
            isLoggedIn(false);
          }

          if (token.rol.length >= 1) {
            setUserName(token?.email);
            setRole(token.rol[0]);
          }

          toast.success(res.message);
          navigate("/");
        })
        .catch((error) => {
          toast.error("Invalid OTP. Please try again!");
          console.error(error.message);
        });
    }
  };
  return (
    <div className="md:mt-28  ">
      <div id="signup" className="bg-gray-100 py-12">
        <h2 className="text-3xl text-blue-900 uppercase font-bold text-center mb-4">
          Signup
        </h2>
        {otpPageEnabled ? (
          <form
            onSubmit={handleOTPSubmit}
            className="bg-white max-w-md mx-auto p-6 rounded-lg shadow-md"
          >
            <div className="mb-4">
              <label htmlFor="otp" className="block">
                Enter OTP:
              </label>
              <input
                type="text"
                id="otp"
                name="otp"
                value={formData.otp}
                onChange={handleChange}
                className="border border-gray-300 rounded-md w-full p-2 mt-1"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
              Verify OTP
            </button>
          </form>
        ) : (
          <>
            <form
              className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 mt-1"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 mt-1"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="mobile" className="block text-gray-700">
                  Mobile No
                </label>
                <input
                  type="phone"
                  id="mobile"
                  name="mobile"
                  placeholder="Enter your mobile number"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 mt-1"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-900 hover:bg-blue-700 text-white py-2 px-4 rounded w-full mt-4"
              >
                Sign Up
              </button>
            </form>
            <p className="text-center mt-4">
              Already have an account?{" "}
              <a href="/login" className="text-blue-900 underline">
                Log in here
              </a>
            </p>
          </>
        )}
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default Signup;
